
export default {
    data() {
        return {
            passwordRules: [],
            conformRules: [],
        }
    },
    methods:{
        async passwordCheck() {
            this.conformRules = [];
            this.passwordRules = [];

            if (!/^(?=.*?[a-z])(?=.*?\d)[a-z\d]{8,100}$/.test(this.password)) {
                this.passwordRules = [
                    "半角英数字をそれぞれ1種類以上含む8文字以上"
                ];
                return false;
            }

            if (this.confirmPassword != this.password) {
                this.conformRules = ["パスワードが確認用と一致しません"];
                return false;
            }

            return true;
        }
    },

    watch: {
        password() {
            this.passwordCheck();
        },
        confirmPassword() {
            this.passwordCheck();
        },
    }
}