<template>
<div>
    <div class="text-center">
        <h4 class="f-gray mb-8">パスワードの変更</h4>
    </div>
    <v-form ref="form">
        <v-text-field v-model="password" label="新しいパスワード" :rules="passwordRules" outlined maxlength="100" />
        <v-text-field v-model="confirmPassword" label="確認用パスワード" :rules="conformRules" outlined maxlength="100" class="mb-6" />

        <v-text-field v-model="nowPassword" label="現在のパスワード" :rules="nowPasswordRules" outlined maxlength="100" />
        <v-btn class="float-right blue_btn" dark @click="passwordChange()">保存する</v-btn>
        <v-btn class="float-left " text @click="$emit('ParentMethod')">戻る</v-btn>
        <div class="clear"></div>
    </v-form>
</div>
</template>

<script>
import Form from "@/mixins/form"
import PasswordCheck from "@/mixins/passwordCheck"


import axios from "axios"
export default {
    mixins: [Form, PasswordCheck],
    data() {
        return {
            password: "",
            confirmPassword: "",
            nowPassword: "",
            nowPasswordRules: [],
        }
    },
    methods: {
        async passwordChange() {
            let password_result = await this.passwordCheck()
            if (!password_result || !this.$refs.form.validate()) {
                return false
            }
            const data = {
                "password": this.password,
                "nowPassword": this.nowPassword
            }
            const response = await axios.post("/user/passwordChange", data)

            if (response.data.error) {
                this.nowPasswordRules = [response.data.error]
            }

            if (response.data.ok) {
                this.$refs.form.reset()
                 this.$emit("ParentMethod")
                 this.$emit("SnackOk")
            }
        }
    },
    watch: {
        nowPassword() {
            this.nowPasswordRules = []
        }
    }
}
</script>
